import Vue from 'vue'
import Router from 'vue-router'
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router)

/* Layout */
import Layout from '../views/layout/Layout'

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/

//所有权限通用路由表
//如首页和登录页和一些不用权限的公用页面
export const constantRouterMap = [
  { path: '/login', component: () => import('@/views/login/index'), hidden: true },
  { path: '/error/401', component: () => import('@/views/error/401'), hidden: true },
  { path: '/error/404', component: () => import('@/views/error/404'), hidden: true },

  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    name: 'Dashboard',
    hidden: true,
    children: [{
      path: 'dashboard',
      component: () => import('@/views/dashboard/index')
    }]
  },
  {
    path: '',
    component: Layout,
    name: 'Info',
    hidden: true,
    children: [{
      path: '/info',
      component: () => import('@/views/info/index')
    }]
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index')
      }
    ],
  }
]


//实例化vue的时候只挂载constantRouter
export default new Router({
  mode: 'history',
  routes: constantRouterMap
});


//异步挂载的路由
//动态需要根据权限加载的路由表
//meta无permissions字段则不做权限控制
export const asyncRouterMap = [

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'User',
    meta: { title: '首页', permissions: ['comprehensiveAnalysis','dataAnalysis','userList','memberLevel','memberOrder'], icon: 'users' },
    children: [
      {
        path: '/finance/comprehensiveAnalysis',
        name: 'ComprehensiveAnalysis',
        component: () => import('@/views/finance/comprehensiveAnalysis'),
        meta: { title: '综合分析', permissions: ['comprehensiveAnalysis'] }
      },
      {
        path: '/finance/dataAnalysis',
        name: 'DataAnalysis',
        component: () => import('@/views/finance/dataAnalysis'),
        meta: { title: '数据分析', permissions: ['dataAnalysis'] }
      },
      {
        path: '/user/list',
        name: 'Users',
        component: () => import('@/views/user/index'),
        meta: { title: '用户列表', permissions: ['userList'] }
      },
      {
        path: '/member/level',
        name: 'MemberLevel',
        component: () => import('@/views/member/level'),
        meta: { title: '会员等级', permissions: ['memberLevel'] }
      },
      {
        path: '/member/order',
        name: 'MemberOrder',
        component: () => import('@/views/member/order'),
        meta: { title: '会员订单', permissions: ['memberOrder'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'School',
    meta: { title: '站点管理', permissions: ['schoolList','buildingList','canteenList','shopCategory','ledgerRole'], icon: 'shop' },
    children: [
      {
        path: '/school/index',
        name: 'SchoolList',
        component: () => import('@/views/school/index'),
        meta: { title: '站点列表', permissions: ['schoolList'] }
      },
      {
        path: '/building/index',
        name: 'BuildingList',
        component: () => import('@/views/building/index'),
        meta: { title: '地址列表', permissions: ['buildingList'] }
      },
      {
        path: '/building/floor',
        name: 'BuildingFloor',
        hidden: true,
        component: () => import('@/views/building/floor'),
        meta: { title: '楼层列表' }
      },
      {
        path: '/school/canteen',
        name: 'SchoolCanteen',
        component: () => import('@/views/school/canteen'),
        meta: { title: '商圈列表', permissions: ['canteenList'] }
      },
      {
        path: '/shop/shopCategory',
        name: 'ShopCategory',
        component: () => import('@/views/shop/shopCategory'),
        meta: { title: '店铺分类', permissions: ['shopCategory'] }
      },
      {
        path: '/school/ledgerRole',
        name: 'LedgerRole',
        component: () => import('@/views/school/ledgerRole'),
        meta: { title: '收益方列表', permissions: ['ledgerRole'] }
      },
      {
        path: '/school/ledgerRolePhone',
        name: 'LedgerRolePhone',
        hidden: true,
        component: () => import('@/views/school/ledgerRolePhone'),
        meta: { title: '绑定的手机号列表' }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Shop',
    meta: { title: '商家管理', permissions: ['recommendShop','shopList','goodsCategory','goodsList'], icon: 'goods' },
    children: [
      {
        path: '/advert/recommendShop',
        name: 'RecommendShop',
        component: () => import('@/views/advert/recommendShop'),
        meta: { title: '推荐店铺', permissions: ['recommendShop'] }
      },
      {
        path: '/shop/index',
        name: 'ShopList',
        component: () => import('@/views/shop/index'),
        meta: { title: '店铺列表', permissions: ['shopList'] }
      },
      {
        path: '/shop/shopManager',
        name: 'ShopManager',
        hidden: true,
        component: () => import('@/views/shop/shopManager'),
        meta: { title: '店铺管理员' }
      },
      {
        path: '/shop/category',
        name: 'GoodsCategory',
        component: () => import('@/views/shop/category'),
        meta: { title: '分类列表', permissions: ['shopList', 'goodsCategory'] }
      },
      {
        path: '/shop/goods',
        name: 'GoodsList',
        component: () => import('@/views/shop/goods'),
        meta: { title: '商品列表', permissions: ['shopList', 'goodsCategory', 'goodsList'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Order',
    meta: { title: '运营管理', permissions: ['orderList','compensateList','financeDay','financeReport','commentList','commentAppeal','withdrawApply','shopWithdraw','distributemanWithdraw','sendmanWithdraw','ledgerRole','spOrder','revenueLog'], icon: 'order' },
    children: [
      {
        path: '/order/list',
        name: 'OrderList',
        component: () => import('@/views/order/index'),
        meta: { title: '订单列表', permissions: ['orderList'] }
      },
      {
        path: '/order/compensate',
        name: 'Compensate',
        component: () => import('@/views/order/compensate'),
        meta: { title: '赔付申请', permissions: ['compensateList'] }
      },
      {
        path: '/finance/day',
        name: 'FinanceDay',
        component: () => import('@/views/finance/day'),
        meta: { title: '经营数据', permissions: ['financeDay'] }
      },
      // {
      //   path: '/finance/bill',
      //   name: 'FinanceBill',
      //   component: () => import('@/views/finance/bill'),
      //   meta: { title: '订单流水', permissions: ['financeBill'] }
      // },
      {
        path: '/finance/report',
        name: 'FinanceReport',
        component: () => import('@/views/finance/report'),
        meta: { title: '财务对账', permissions: ['financeReport'] }
      },
      {
        path: '/comment/list',
        name: 'CommentList',
        component: () => import('@/views/comment/index'),
        meta: { title: '评价列表', permissions: ['commentList'] }
      },
      {
        path: '/comment/appeal',
        name: 'CommentAppeal',
        component: () => import('@/views/comment/appeal'),
        meta: { title: '评价申诉', permissions: ['commentAppeal'] }
      },
      {
        path: '/finance/withdraw',
        name: 'WithdrawApply',
        component: () => import('@/views/finance/withdraw'),
        meta: { title: '提现申请', permissions: ['withdrawApply'] }
      },
      {
        path: '/shop/withdraw',
        name: 'ShopWithdraw',
        component: () => import('@/views/shop/withdraw'),
        meta: { title: '商铺提现', permissions: ['shopWithdraw'] }
      },
      {
        path: '/distributeman/withdraw',
        name: 'DistributemanWithdraw',
        component: () => import('@/views/distributeman/withdraw'),
        meta: { title: '交付员提现', permissions: ['distributemanWithdraw'] }
      },
      {
        path: '/sendman/withdraw',
        name: 'SendmanWithdraw',
        component: () => import('@/views/sendman/withdraw'),
        meta: { title: '配送员提现', permissions: ['sendmanWithdraw'] }
      },
      {
        path: '/school/spOrder',
        name: 'spOrder',
        component: () => import('@/views/school/spOrder'),
        meta: { title: '分润列表', permissions: ['spOrder'] }
      },
      {
        path: '/school/revenueLog',
        name: 'revenueLog',
        component: () => import('@/views/school/revenueLog'),
        meta: { title: '分润记录', permissions: ['ledgerRole','spOrder','revenueLog'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Coupon',
    meta: { title: '营销管理', permissions: ['couponList','advertList','advertTimingDisplay','banner','bannerTimingDisplay','bestSelling','bestSellingTimingDisplay','flashSaleTime','flashSaleGoods','dailySpecialGoods'], icon: 'coupon' },
    children: [
      {
        path: '/coupon/index',
        name: 'CouponList',
        component: () => import('@/views/coupon/index'),
        meta: { title: '优惠券列表', permissions: ['couponList'] }
      },
      // {
      //   path: '/shop/activity',
      //   name: 'ActivityList',
      //   component: () => import('@/views/shop/shopActivity'),
      //   meta: { title: '商品活动' }
      // },
      // {
      //   path: '/coupon/couponPackage',
      //   name: 'CouponPackage',
      //   component: () => import('@/views/coupon/couponPackage'),
      //   meta: { title: '优惠券套餐', permissions: ['couponPackage'] }
      // },
      // {
      //   path: '/coupon/couponPackageOrder',
      //   name: 'CouponPackageOrder',
      //   component: () => import('@/views/coupon/couponPackageOrder'),
      //   meta: { title: '优惠券套餐订单', permissions: ['couponPackageOrder'] }
      // },
      {
        path: '/advert/index',
        name: 'AdvertIndex',
        component: () => import('@/views/advert/index'),
        meta: { title: '弹窗广告', permissions: ['advertList'] }
      },
      {
        path: '/advert/advertTimingDisplay',
        name: 'AdvertTimingDisplay',
        component: () => import('@/views/advert/advertTimingDisplay'),
        meta: { title: '弹窗设置', permissions: ['advertTimingDisplay'] }
      },
      // {
      //   path: '/advert/deviceAd',
      //   name: 'DeviceAd',
      //   component: () => import('@/views/advert/deviceAd'),
      //   meta: { title: '餐柜广告', permissions: ['deviceAd'] }
      // },
      {
        path: '/advert/banner',
        name: 'Banner',
        component: () => import('@/views/advert/banner'),
        meta: { title: '轮播图广告', permissions: ['banner'] }
      },
      {
        path: '/advert/bannerTimingDisplay',
        name: 'BannerTimingDisplay',
        component: () => import('@/views/advert/bannerTimingDisplay'),
        meta: { title: '轮播图设置', permissions: ['bannerTimingDisplay'] }
      },
      {
        path: '/advert/bestSelling',
        name: 'BestSelling',
        component: () => import('@/views/advert/bestSelling'),
        meta: { title: '外媒广告', permissions: ['bestSelling'] }
      },
      {
        path: '/advert/bestSellingTimingDisplay',
        name: 'BestSellingTimingDisplay',
        component: () => import('@/views/advert/bestSellingTimingDisplay'),
        meta: { title: '外媒设置', permissions: ['bestSellingTimingDisplay'] }
      },
      {
        path: '/advert/flashSaleTime',
        name: 'FlashSaleTime',
        component: () => import('@/views/advert/flashSaleTime'),
        meta: { title: '抢购时间', permissions: ['flashSaleTime'] }
      },
      {
        path: '/advert/flashSaleGoods',
        name: 'FlashSaleGoods',
        component: () => import('@/views/advert/flashSaleGoods'),
        meta: { title: '抢购设置', permissions: ['flashSaleGoods'] }
      },
      {
        path: '/advert/addFlashSaleGoods',
        name: 'AddFlashSaleGoods',
        hidden: true,
        component: () => import('@/views/advert/addFlashSaleGoods'),
        meta: { title: '新增抢购' },
      },
      {
        path: '/advert/dailySpecialGoods',
        name: 'DailySpecialGoods',
        component: () => import('@/views/advert/dailySpecialGoods'),
        meta: { title: '每日特惠', permissions: ['dailySpecialGoods'] }
      },
      {
        path: '/advert/addDailySpecialGoods',
        name: 'AddDailySpecialGoods',
        hidden: true,
        component: () => import('@/views/advert/addDailySpecialGoods'),
        meta: { title: '新增特惠' }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Team',
    meta: { title: '团队管理', permissions: ['sendmanList','sendmanSetting','distributemanList','distributemanOrder','attendanceConfig','attendanceCodeList','attendanceDistributeman','attendanceSendman'], icon: 'sendman' },
    children: [
      {
        path: '/sendman/index',
        name: 'SendmanList',
        component: () => import('@/views/sendman/index'),
        meta: { title: '配送员列表', permissions: ['sendmanList'] }
      },
      {
        path: '/sendman/setting',
        name: 'SendmanSetting',
        component: () => import('@/views/sendman/setting'),
        meta: { title: '配送设置', permissions: ['sendmanSetting'] }
      },
      {
        path: '/sendman/settle',
        name: 'SendmanSettle',
        hidden: true,
        component: () => import('@/views/sendman/settle'),
        meta: { title: '结算' }
      },
      {
        path: '/distributeman/index',
        name: 'DistributemanIndex',
        component: () => import('@/views/distributeman/index'),
        meta: { title: '交付员列表', permissions: ['distributemanList'] }
      },
      {
        path: '/distributeman/order',
        name: 'DistributemanOrder',
        component: () => import('@/views/distributeman/order'),
        meta: { title: '交付订单', permissions: ['distributemanOrder'] }
      },
      {
        path: '/attendance/config',
        name: 'attendanceConfig',
        component: () => import('@/views/attendance/config'),
        meta: { title: '考勤设置', permissions: ['attendanceConfig'] }
      },
      {
        path: '/attendance/codeList',
        name: 'attendanceCodeList',
        component: () => import('@/views/attendance/codeList'),
        meta: { title: '考勤码列表', permissions: ['attendanceCodeList'] }
      },
      {
        path: '/attendance/distributeman',
        name: 'attendanceDistributeman',
        component: () => import('@/views/attendance/distributeman'),
        meta: { title: '交付员考勤', permissions: ['attendanceDistributeman'] }
      },
      {
        path: '/attendance/sendman',
        name: 'attendanceSendman',
        component: () => import('@/views/attendance/sendman'),
        meta: { title: '配送员考勤', permissions: ['attendanceSendman'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'LiveWalletReconciliation',
    meta: { title: '核销管理', permissions: ['liveWalletUserRecord','liveWalletCollectionRecord','liveWalletReport','liveWallet','timingRechargeLiveWallet'], icon: 'wallet' },
    children: [
      {
        path: '/liveWallet/userRecord',
        name: 'UserRecord',
        component: () => import('@/views/wallet/liveWalletUserRecord'),
        meta: { title: '核销列表', permissions: ['liveWalletUserRecord'] }
      },
      {
        path: '/liveWallet/collectionRecord',
        name: 'CollectionRecords',
        component: () => import('@/views/wallet/collectionRecord'),
        meta: { title: '收款记录', permissions: ['liveWalletCollectionRecord'] }
      },
      {
        path: '/finance/liveWalletReport',
        name: 'liveWalletReport',
        component: () => import('@/views/finance/liveWalletReport'),
        meta: { title: '核销报表', permissions: ['liveWalletReport'] }
      },
      {
        path: '/wallet/liveWallet',
        name: 'LiveWallet',
        component: () => import('@/views/wallet/liveWallet'),
        meta: { title: '核销钱包', permissions: ['liveWallet'] }
      },
      {
        path: "/wallet/liveWalletLog",
        name: "LiveWalletLog",
        hidden: true,
        component: () => import("@/views/wallet/liveWalletLog"),
        meta: { title: "核销钱包明细" }
      },
      {
        path: "/wallet/timingRechargeLiveWallet",
        name: "TimingRechargeLiveWallet",
        component: () => import("@/views/wallet/timingRechargeLiveWallet"),
        meta: { title: "定时充值", permissions: ["timingRechargeLiveWallet"] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Wallet',
    meta: { title: '钱包管理', permissions: ['walletCharge','walletOrder','walletRechargeRecovery','shopWallet',
        'distributemanWallet','sendmanWallet','userWallet'], icon: 'wallet' },
    children: [
      {
        path: '/wallet/charge',
        name: 'WalletCharge',
        component: () => import('@/views/wallet/charge'),
        meta: { title: '充值设置', permissions: ['walletCharge'] }
      },
      {
        path: '/wallet/order',
        name: 'WalletOrder',
        component: () => import('@/views/wallet/order'),
        meta: { title: '充值订单', permissions: ['walletOrder'] }
      },
      {
        path: '/wallet/rechargeRecovery',
        name: 'WalletRechargeRecovery',
        component: () => import('@/views/wallet/rechargeRecovery'),
        meta: { title: '充值回收', permissions: ['walletRechargeRecovery'] }
      },
      {
        path: '/wallet/shopWallet',
        name: 'ShopWallet',
        component: () => import('@/views/wallet/shopWallet'),
        meta: { title: '店铺钱包', permissions: ['shopWallet'] }
      },
      {
        path: "/wallet/shopWalletLog",
        name: "ShopWalletLog",
        hidden: true,
        component: () => import("@/views/wallet/shopWalletLog"),
        meta: { title: "店铺钱包明细" }
      },
      {
        path: '/wallet/distributemanWallet',
        name: 'DistributemanWallet',
        component: () => import('@/views/wallet/distributemanWallet'),
        meta: { title: '交付员钱包', permissions: ['distributemanWallet'] }
      },
      {
        path: "/wallet/distributemanWalletLog",
        name: "DistributemanWalletLog",
        hidden: true,
        component: () => import("@/views/wallet/distributemanWalletLog"),
        meta: { title: "交付员钱包明细" }
      },
      {
        path: '/wallet/sendmanWallet',
        name: 'SendmanWallet',
        component: () => import('@/views/wallet/sendmanWallet'),
        meta: { title: '配送员钱包', permissions: ['sendmanWallet'] }
      },
      {
        path: "/wallet/sendmanWalletLog",
        name: "SendmanWalletLog",
        hidden: true,
        component: () => import("@/views/wallet/sendmanWalletLog"),
        meta: { title: "配送员钱包明细" }
      },
      {
        path: '/wallet/userWallet',
        name: 'UserWallet',
        component: () => import('@/views/wallet/userWallet'),
        meta: { title: '用户钱包', permissions: ['userWallet'] }
      },
      {
        path: "/wallet/userWalletLog",
        name: "UserWalletLog",
        hidden: true,
        component: () => import("@/views/wallet/userWalletLog"),
        meta: { title: "用户钱包明细" }
      },

    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'System',
    meta: { title: '系统设置', permissions: ['permissionList','adminList','company','feedback','agreement'], icon: 'system' },
    children: [
      {
        path: "/permission/list",
        name: "Permissions",
        component: () => import("@/views/permission/index"),
        meta: { title: "权限菜单", permissions: ["permissionList"] }
      },
      {
        path: '/admin/list',
        name: 'Admin',
        component: () => import('@/views/admin/index'),
        meta: { title: '权限分配', permissions: ['adminList'] }
      },
      {
        path: '/site/company',
        name: 'company',
        component: () => import('@/views/site/company'),
        meta: { title: '信息公示', permissions: ['company'] }
      },
      {
        path: '/site/feedback',
        name: 'feedback',
        component: () => import('@/views/site/feedback'),
        meta: { title: '意见反馈', permissions: ['feedback'] }
      },
      {
        path: '/site/agreement',
        name: 'agreement',
        component: () => import('@/views/site/agreement'),
        meta: { title: '服务协议', permissions: ['agreement'] }
      },
      {
        path: '/site/agreementDetail',
        name: 'agreementDetail',
        hidden: true,
        component: () => import('@/views/site/agreementDetail'),
        meta: { title: '服务协议详情' }
      },
    ]
  },

  // {
  //   path: '',
  //   component: Layout,
  //   alwaysShow: true,
  //   name: 'Device',
  //   meta: { title: '餐柜管理', permissions: ['deviceList','deviceBox','deviceOrder','deviceUse'], icon: 'cupboard' },
  //   children: [
  //     {
  //       path: '/device/index',
  //       name: 'DeviceIndex',
  //       component: () => import('@/views/device/index'),
  //       meta: { title: '设备列表', permissions: ['deviceList'] }
  //     },
  //     {
  //       path: '/device/box',
  //       name: 'DeviceBox',
  //       component: () => import('@/views/device/box'),
  //       meta: { title: '箱子列表', permissions: ['deviceBox'] }
  //     },
  //     {
  //       path: '/device/order',
  //       name: 'DeviceOrder',
  //       component: () => import('@/views/device/order'),
  //       meta: { title: '投餐订单', permissions: ['deviceOrder'] }
  //     },
  //     {
  //       path: '/device/use',
  //       name: 'DeviceUse',
  //       component: () => import('@/views/device/use'),
  //       meta: { title: '投餐统计', permissions: ['deviceUse'] }
  //     },
  //     {
  //       path: '/device/useDetail',
  //       name: 'DeviceUseDetail',
  //       hidden: true,
  //       component: () => import('@/views/device/useDetail'),
  //       meta: { title: '投餐统计详情' }
  //     },
  //   ]
  // },

  // {
  //   path: '',
  //   component: Layout,
  //   alwaysShow: true,
  //   name: 'Site',
  //   meta: { title: '建站管理', permissions: ['join','systemConfig'], icon: 'site' },
  //   children: [
  //     {
  //       path: '/site/join',
  //       name: 'join',
  //       component: () => import('@/views/site/join'),
  //       meta: { title: '加盟申请', permissions: ['join'] }
  //     },
  //     {
  //       path: '/site/systemConfig',
  //       name: 'systemConfig',
  //       component: () => import('@/views/site/systemConfig'),
  //       meta: { title: '系统配置', permissions: ['systemConfig'] }
  //     },
  //   ]
  // },

  // {
  //   path: '',
  //   component: Layout,
  //   alwaysShow: true,
  //   name: 'Saleman',
  //   meta: { title: '业务员管理', permissions: ['salemanList'], icon: 'staff' },
  //   children: [
  //     {
  //       path: '/saleman/index',
  //       name: 'Saleman',
  //       component: () => import('@/views/saleman/index'),
  //       meta: { title: '业务员列表', permissions: ['salemanList'] }
  //     },
  //     {
  //       path: '/saleman/income',
  //       name: 'SalemanIncome',
  //       hidden: true,
  //       component: () => import('@/views/saleman/income'),
  //       meta: { title: '提成' }
  //     },
  //   ]
  // },

  // {
  //   path: '',
  //   component: Layout,
  //   alwaysShow: true,
  //   name: 'Log',
  //   meta: { title: '日志管理', permissions: ['logList'], icon: 'log' },
  //   children: [
  //     {
  //       path: '/log/list',
  //       name: 'LogList',
  //       component: () => import('@/views/log/index'),
  //       meta: { title: '日志列表', permissions: ['logList'] }
  //     },
  //   ]
  // },

  // {
  //   path: '',
  //   component: Layout,
  //   alwaysShow: true,
  //   name: 'CCB',
  //   meta: { title: '龙存管管理', permissions: ['userAccount','platAccount'], icon: 'CCB' },
  //   children: [
  //     // {
  //     //   path: '/ccb/list',
  //     //   name: 'CCB',
  //     //   component: () => import('@/views/ccb/index'),
  //     //   meta: { title: '登记簿', permissions: ['ccb'] }
  //     // },
  //     {
  //       path: '/ccb/userAccount',
  //       name: 'userAccount',
  //       component: () => import('@/views/ccb/userAccount'),
  //       meta: { title: '学校账户', permissions: ['userAccount'] }
  //     },
  //     {
  //       path: '/ccb/platAccount',
  //       name: 'platAccount',
  //       component: () => import('@/views/ccb/platAccount'),
  //       meta: { title: '平台账户', permissions: ['platAccount'] }
  //     },
  //   ]
  // },

  { path: '*', redirect: '/error/404', hidden: true },
]
